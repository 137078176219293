import { useQuery } from '@apollo/client';
import { Box, Card, CardHeader, CardBody, Center, GridItem, Heading, Spinner, Stack, StackDivider, Text  } from '@chakra-ui/react';

import moment from 'moment';

import { useLocation } from 'react-router-dom';

import { MarkdownRenderer } from '../';

import {
  QUERY_ANNOUNCEMENTS,
} from '../../Queries';

import { useUser } from '../../Context';

export const AnnouncementsSidebar = () => {
  const location = useLocation();

  const { user } = useUser();

  const { pathname } = location;

  let name = 'General';
  let type = ['GENERAL'];

  if (pathname.includes('/about-us')) {
    name = 'General';
    type = ['GENERAL'];
  }
  
  if (pathname.includes('/facilities')) {
    name = 'Shotgun and Rifle/Pistol';
    type = ['SHOTGUN', 'RIFLE_PISTOL'];
  }
  
  if (pathname.includes('/facilities/shotgun')) {
    name = 'Shotgun';
    type = ['SHOTGUN'];
  }

  if (pathname.includes('/facilities/rifle-pistol')) {
    name = 'Rife/Pistol';
    type = ['RIFLE_PISTOL'];
  }
  
  if (pathname.includes('/matches-competition')) {
    name = 'Matches';
    type = ['MATCHES'];
  }
  
  if (pathname.includes('/membership')) {
    name = 'Members';
    type = ['MEMBERS'];
  }

  const { loading, error, data } = useQuery(QUERY_ANNOUNCEMENTS, {
    variables: {
      announcement_types: type,
      public_only: user === undefined
    },
  });

  return (
    <GridItem id="sidebar" colSpan={{ base: 12, md: 3 }} mt={{ base: 0, md: 8 }} mb={4}>
      {loading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Card>
          <CardHeader>
            <Heading as="h4" size='md' fontWeight="600">{`${name} Announcements`}</Heading>
          </CardHeader>
          <CardBody>
            {data && data?.queryAnnouncements?.announcements?.length > 0 ? (
              <Stack divider={<StackDivider />} spacing='4'>
                {data?.queryAnnouncements?.announcements?.map((announcement) => (
                  <Box>
                    <Heading as="h4" size='xs'>
                      {moment.unix(announcement.announcement_details.announcement_date).format('MMMM Do YYYY')}
                    </Heading>
                    <Box pt='2' fontSize='sm'>
                      <MarkdownRenderer children={announcement.announcement_details.announcement_text} />
                    </Box>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Text pt='2' fontSize='sm'>
                There are currently no new {name} announcements.
              </Text>
            )}
          </CardBody>
        </Card>
      )}
      {error && JSON.stringify(error)}
    </GridItem>
  )
}