import { Box, Divider, Text } from '@chakra-ui/react'

export const Footer = () => {
  return (
    <Box mt={4} p="24px 0" px={{ base: '42px', md: '84px' }}>
      <Divider />
      <Text pt={6}>© 2023 by Golden Gun Club.</Text>
    </Box>
  );
};
