import {
    Text,
    Link,
    Box,
    VStack
} from '@chakra-ui/react';

import pageContent from './faq.json';


export const FAQ = () => {
  const { contact } = pageContent;

  return (
    <Box>
      <Box>
        <Text mb={4}>{contact.heading}</Text>
        <VStack align="flex-start">
          <Box>{contact.address}</Box>
          <Box>{contact.phoneOne}<br />{contact.phoneTwo}</Box>
          <Box><Link href={`mailto:${contact.email}`}>{contact.email}</Link></Box>
        </VStack>
      </Box>
    </Box>
  )
}