import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, HttpLink } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { createAuthLink } from 'aws-appsync-auth-link';
import { Amplify, Auth } from 'aws-amplify';
// import { useEffect } from "react";

import { AppRoutes } from "./Components";
import { AuthContext } from './Context';
import { CONSTANTS } from './constants';

const { APPSYNC } = CONSTANTS;

Amplify.configure({
  userPoolId: window.__env__.cognito.cognito_user_pool,
  region: window.__env__.cognito.cognito_region,
  userPoolWebClientId: window.__env__.cognito.user_pool_client_id,
});

const { url } = APPSYNC;

const getCurrentSession = async () => {
  try {
    const res = await Auth.currentSession();
    return res.getIdToken().getJwtToken();
  } catch (err) {
    console.error(err);
    return false;
  }
}

const appsyncLink = new HttpLink({ uri: url });

// const apiAuthLink = createAuthLink({
//   auth: {
//     type: 'API_KEY',
//     apiKey: null,
//   },
// });

const cognitoAuthLink = createAuthLink({
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => getCurrentSession(),
  },
});

const awsLink = ApolloLink.split((operation) => {
  return (operation.variables.public_only === false)
}, cognitoAuthLink, appsyncLink)

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: ApolloLink.from([ awsLink, appsyncLink ]),
  cache,
})

export const App = () => {
  // useEffect(() => {
  //   async function checkUserLoggedIn() {
  //     try {
  //       const user = await Auth.currentAuthenticatedUser();
  //       if (user) {
  //         console.log(user);
  //       }
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
  //   checkUserLoggedIn();
  // }, []);

  return (
    <ApolloProvider client={client}>
      <AuthContext>
        <AppRoutes />
      </AuthContext>
    </ApolloProvider>
  );
};
