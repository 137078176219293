import {Box, Center, Table, TableContainer, Tbody, Td, Text, Tr, VStack} from '@chakra-ui/react';

import { ButtonCallToAction } from '../../Components';

import matchesCompetitionContent from './matches-competition.json';

const { additional } = matchesCompetitionContent;

export const MatchesCompetition = () => {
  return (
    <Box mt={6}>
      <Center>
        <Text as="h3" color="#000000" fontSize={{ base: '22px' }} fontWeight="600">{additional.title}</Text>
      </Center>
        <Box>Committee/Match Directors:</Box>
      <TableContainer my="16px">
        <Table size='sm'>
          <Tbody>
            <Tr>
              <Td>Black Powder Cartridge</Td>
              <Td>Mike Rix</Td>
              <Td>rmrix@hotmail.com</Td>
            </Tr>
            <Tr>
              <Td>Cowboy Silhouette</Td>
              <Td>Rich DelPizzo</Td>
              <Td>303-917-2875</Td>
            </Tr>
            <Tr>
              <Td>Service Rifle</Td>
              <Td>Matt Anthony</Td>
              <Td>statmatt@gmail.com, 720-775-5243</Td>
            </Tr>
            <Tr>
              <Td>Bowling Pin Pistol</Td>
              <Td>Jared Pedri</Td>
              <Td>jared@effectiveft.com</Td>
            </Tr>
            <Tr>
              <Td>NRA Action Pistol</Td>
              <Td>Jim Flagg</Td>
              <Td>b737jim@aol.com</Td>
            </Tr>
            <Tr>
              <Td>Shotgun/Clubhouse</Td>
              <Td>Paul Pedri</Td>
              <Td>303-366-6970</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <VStack mt={4} spacing={8}>
        {additional.ctas.map((cta) => (
          <ButtonCallToAction
            image={cta.image}
            title={cta.title}
            subtitle={cta.subtitle}
            buttonLink={cta.buttonLink}
            buttonText={cta.buttonText}
          />
        ))}
      </VStack>
    </Box>
  );
};
