import { useApolloClient, useMutation } from '@apollo/client';

import {
  Center,
  Spinner,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  VStack,
  useDisclosure,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import { Select } from "chakra-react-select";

import moment from 'moment';

import { useCallback, useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';

import {
  ADD_NEWSLETTER,
  QUERY_PUBLISHED_NEWSLETTERS,
} from '../../../../Queries';

import { useUser } from '../../../../Context';

const newsletterStatusOptions = [
  {
    label: 'Published',
    value: 'PUBLISHED',
  },
  // {
  //   label: 'Draft',
  //   value: 'DRAFT',
  // },
];

export const AddNewsletterModal = ({ isOpen, onClose }) => {
  const { user } = useUser();
  const toast = useToast();
  const client = useApolloClient();

  const [fileUploadURL, setFileUploadURL] = useState(null);
  const [fileUploadHeaders, setFileUploadHeaders] = useState(null);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const defaultValues = {
    newsletter_id: '',
    newsletter_status: '',
    newsletter_name: '',
    newsletter_date: null,
    newsletter_published_by: '',
    newsletter_published_date: null,
    newsletter_file: null,
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({
    defaultValues
  });

  const clearForm = () => {
    setValue('newsletter_id', defaultValues?.newsletter_id);
    setValue('newsletter_status', defaultValues?.newsletter_status);
    setValue('newsletter_name', defaultValues?.newsletter_name);
    setValue('newsletter_date', defaultValues?.newsletter_date);
    setValue('newsletter_published_date', defaultValues?.newsletter_published_date);
    setValue('newsletter_file', defaultValues?.newsletter_file);
  };

  const formData = watch();

  const [addNewsletter, { data: addNewsletterData, loading: addNewsletterLoading, error: addNewsletterError }] = useMutation(ADD_NEWSLETTER, {
    skip: !user,
    variables: {
      public_only: user === undefined,
      newsletter_details: {
        newsletter_status: formData?.newsletter_status,
        newsletter_name: formData?.newsletter_name,
        newsletter_date: formData?.newsletter_date,
        newsletter_published_date: formData?.newsletter_published_date,
        newsletter_published_by: user?.username,
      },
      newsletter_id: formData?.newsletter_id,
    },
    refetchQueries: [QUERY_PUBLISHED_NEWSLETTERS],
    onCompleted: (data) => {
      setFileUploadURL(data?.addNewsletter?.newsletter_details?.newsletter_upload_url?.url);
      setFileUploadHeaders(JSON.parse(data?.addNewsletter?.newsletter_details?.newsletter_upload_url?.fields));
    },
    onError: () => {
      toast({
        title: 'Newsletter not added.',
        description: "",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  });

  const onSubmit = () => {
    addNewsletter();
  };

  const submitFile = async () => {
    const formDataObject = new FormData();

    formDataObject.append('key', fileUploadHeaders?.key);
    formDataObject.append('policy', fileUploadHeaders?.policy);
    formDataObject.append('x-amz-algorithm', fileUploadHeaders['x-amz-algorithm']);
    formDataObject.append('x-amz-credential', fileUploadHeaders['x-amz-credential']);
    formDataObject.append('x-amz-date', fileUploadHeaders['x-amz-date']);
    formDataObject.append('x-amz-security-token', fileUploadHeaders['x-amz-security-token']);
    formDataObject.append('x-amz-signature', fileUploadHeaders['x-amz-signature']);
    formDataObject.append("file", formData.newsletter_file);

    setFileUploadLoading(true);

    return fetch(fileUploadURL, {
      method: "POST",
      body: formDataObject,
    }).then((response) => {
      if (response.ok) {
        toast({
          title: 'Newsletter added.',
          description: "",
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        onClose();
        clearForm();
        setFileUploadLoading(false);
        // client?.refetchQueries([QUERY_PUBLISHED_NEWSLETTERS]);
      } else {
        toast({
          title: 'Newsletter not added.',
          description: "",
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        setFileUploadLoading(false);
      }
    });
  };

  useEffect(() => {
    if (fileUploadURL) {
      submitFile();
    }
  }, [fileUploadURL]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Newsletter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack mb={2} as="form" alignItems="start" onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.newsletter_name}>
                <FormLabel htmlFor='newsletter_name'>Newsletter name</FormLabel>
                <Input
                  id='newsletter_name'
                  placeholder='newsletter name'
                  {...register('newsletter_name', {
                    required: 'This is required',
                    minLength: { value: 4, message: 'Minimum length should be 4' },
                  })}
                />
                <FormErrorMessage>
                  {errors.newsletter_name && errors.newsletter_name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.newsletter_id}>
                <FormLabel htmlFor='newsletter_id'>Newsletter ID</FormLabel>
                <Input
                  id='newsletter_id'
                  placeholder='newsletter id'
                  {...register('newsletter_id', {
                    required: 'This is required',
                    minLength: { value: 4, message: 'Minimum length should be 4' },
                  })}
                />
                <FormErrorMessage>
                  {errors.newsletter_id && errors.newsletter_id.message}
                </FormErrorMessage>
              </FormControl>
              <Controller
                control={control}
                name="newsletter_status"
                rules={{ required: "This is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  // fieldState: { error }
                }) => (
                  <FormControl isInvalid={errors.newsletter_status} id="newsletter_status">
                    <FormLabel>Newsletter status</FormLabel>
                    <Select
                      ref={ref}
                      name={name}
                      onBlur={onBlur}
                      onChange={(value) => onChange(value.value)}
                      options={newsletterStatusOptions}
                      placeholder="newsletter status"
                      value={newsletterStatusOptions.find((option) => option.value === value)}
                      // closeMenuOnSelect={false}
                    />
                    <FormErrorMessage>{errors.newsletter_status && errors.newsletter_status.message}</FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="newsletter_date"
                rules={{ required: "This is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  // fieldState: { error }
                }) =>  (
                  <FormControl id="newsletter_date" isInvalid={errors.newsletter_date}>
                    <FormLabel>Newsletter date</FormLabel>
                    <Input
                      type="date"
                      name={name}
                      value={value ? moment.utc(value * 1000).format('yyyy-MM-DD') : undefined}
                      onChange={(event) => onChange(event.target.value ? moment(event.target.value).unix(): "")}
                      id='newsletter_date'
                      placeholder='newsletter date'
                    />
                    <FormErrorMessage>
                      {errors.newsletter_date && errors.newsletter_date.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="newsletter_published_date"
                rules={{ required: "This is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  // fieldState: { error }
                }) =>  (
                  <FormControl id="newsletter_published_date" isInvalid={errors.newsletter_published_date}>
                    <FormLabel>Newsletter published date</FormLabel>
                    <Input
                      type="date"
                      name={name}
                      value={value ? moment.utc(value * 1000).format('yyyy-MM-DD') : undefined}
                      onChange={(event) => onChange(event.target.value ? moment(event.target.value).unix(): "")}
                      id='newsletter_published_date'
                      placeholder='newsletter_published_date date'
                    />
                    <FormErrorMessage>
                      {errors.newsletter_published_date && errors.newsletter_published_date.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="newsletter_file"
                rules={{ required: "This is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  // fieldState: { error }
                }) =>  { console.log(value); return (
                <FormControl isInvalid={errors.newsletter_file}>
                  <FormLabel htmlFor='newsletter_file'>Newsletter file (.pdf)</FormLabel>
                  <Input
                    name={name}
                    type='file'
                    id='newsletter_file'
                    accept='application/pdf'
                    placeholder='newsletter file'
                    value={value?.fileName}
                    onChange={(event) => {
                      console.log(event)
                      onChange(event.target.files[0]);
                    }}
                  />
                  <FormErrorMessage>
                    {errors.newsletter_file && errors.newsletter_file.message}
                  </FormErrorMessage>
                </FormControl>
                )}}
              />
              <ButtonGroup pt={2}>
                <Button
                  type='submit'
                  colorScheme='teal' 
                  isLoading={
                    isSubmitting ||
                    fileUploadLoading ||
                    addNewsletterLoading
                  } 
                  isDisabled={
                    isSubmitting ||
                    fileUploadLoading ||
                    addNewsletterLoading
                  }
                >
                  Submit
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
