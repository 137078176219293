import { React, useEffect } from 'react';
import { Box, Card, CardBody, CardHeader, Grid, GridItem, Image, Link, Stack, Text, VStack, Button } from '@chakra-ui/react'
import { AnnouncementsHomePanel, Footer, MarkdownRenderer, WithSubnavigation } from '../../Components';
import pageContent from './home.json';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

const setCookie = (name, value, days) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
}

const getCookie = (name) => {
 const cookies = document.cookie
   .split("; ")
   .find((row) => row.startsWith(`${name}=`));

 return cookies ? cookies.split("=")[1] : null;
};

export const Home = () => {
  const { contact, masthead, sections } = pageContent;
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    const cookieval = getCookie("hasclosedmodal");
    if (cookieval !== "y") {
      onOpen();
    }
  }, [onOpen]);

  return (
    <>
    <Box bg="#F7F7F7">
      {WithSubnavigation()}
      <Box>
        <Box pos="relative">
          <Image pt={{ base: "86px", md: "0" }} w="100%" src={masthead.image} alt='' />
          <Box
            bottom={{ md: '35%' }}
            pos={{ base: 'static', md: 'absolute' }}
            px={{ base: '42px', md: '84px' }}
          >
            <Text as="h1" color="#FD5521" fontSize={{ base: '40px', md: '60px' }} fontWeight="700">{masthead.title}</Text>
            <Text as="h2" mb="40px" color={{ base: '#000', md: '#000' }} fontSize={{ base: '21px', md: '35px' }} fontWeight="700">{masthead.subtitle}</Text>
            <Stack spacing={6} direction={{base: 'column', md: 'row'}}>
              <Link href="/membership" bg="#FD5521" color="#FFF" p="22px 32px" fontWeight="600" borderRadius="6px" textAlign="center">View Membership Details</Link>
              <Link href="/matches-competition" bg="#FD5521" color="#FFF" p="22px 32px" fontWeight="600" borderRadius="6px" textAlign="center">View Match Programs</Link>
              <Link href="/about-us" bg="#FD5521" color="#FFF" p="22px 32px" fontWeight="600" borderRadius="6px" textAlign="center">Contact Us</Link>
            </Stack>
          </Box>
        </Box>
        <Box id="main">
          <Box>
            <iframe
                title="Current Weather Conditions"
                src="https://cdnres.willyweather.com/widget/loadView.html?id=121560"
                width="650"
                height="92"
                scrolling="no"></iframe>
          </Box>
          <Grid gap={10} m="24px 0" px={{ base: '42px', md: '84px' }} templateColumns={{ base: 'repeat(12, 1fr)' }}>
            <GridItem colSpan={{ base: 12, lg: 8 }} mt={{ base: 4, md: 8 }}>
              {sections.map((section, index) => index % 2 === 0 ? (
              <Box key={index}>
                <Stack
                  spacing='24px'
                  direction={{base: 'column', md: 'row'}}
                >
                  <Box w={{base: '100%', md: '40%' }}>
                    <Text as="h2" mb="40px" fontSize={{ base: '24px', md: '36px'}} fontWeight="700">{section.heading}</Text>
                    <Box>
                      <MarkdownRenderer children={section.content} />
                    </Box>
                  </Box>
                  <Box w={{base: '100%', md: '60%' }}>
                    <Image src={section.image} />
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box m="24px 0" key={index}>
                <Stack
                  spacing='24px'
                  direction={{base: 'column', md: 'row'}}
                >
                  <Box w={{base: '100%', md: '50%' }}>
                    <Image src={section.image} />
                  </Box>
                  <Box w={{base: '100%', md: '50%' }}>
                    <Text as="h2" mb="40px" fontSize={{ base: '24px', md: '36px'}}  fontWeight="700">{section.heading}</Text>
                    <Box>
                      <MarkdownRenderer children={section.content} />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            ))}
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }} mt={{ base: 4, md: 8 }}>
              <AnnouncementsHomePanel />
            </GridItem>
          </Grid>
          <Card p="48px" mt="48px" mb="24px" mx={{ base: '42px', md: '84px' }}>
            <CardHeader pb={0}>
              <Text as="h3" color="#000" fontSize={{ base: '32px', md: '56px' }} fontWeight="700">
                {contact.heading}
              </Text>
            </CardHeader>
            <CardBody>
              <VStack align="flex-start">
                <Box>{contact.address}</Box>
                <Box>{contact.phoneOne}<br />{contact.phoneTwo}</Box>
                <Box><Link href={`mailto:${contact.email}`}>{contact.email}</Link></Box>
              </VStack>
            </CardBody>
          </Card>
          </Box>
        </Box>
      <Footer />
    </Box>
    </>
  );
}
