import { useMutation, useQuery } from '@apollo/client';

import {
  Center,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Textarea,
  HStack,
  Button,
  Select,
  useToast,
  Input,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';

import moment from 'moment';

import {
  ADD_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  QUERY_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT,
} from '../../../../Queries';

import { useUser } from '../../../../Context';

export const Announcements = () => {
  const { user } = useUser();

  const toast = useToast()

  const announcementTypeOptions = [
    {
      label: 'General',
      value : 'GENERAL',
    },
    {
      label: 'Matches',
      value : 'MATCHES',
    },
    {
      label: 'Members',
      value : 'MEMBERS',
    },
    {
      label: 'Rifle/Pistol',
      value : 'RIFLE_PISTOL',
    },
    {
      label: 'Shotgun',
      value : 'SHOTGUN',
    },
  ]

  const announcementIsPublicOptions = [
    {
      label: 'True',
      value: true,
    },
    {
      label: 'False',
      value: false,
    }
  ];

  /* Query Announcements */
  const { loading: announcementsLoading, error: announcementsError, data: announcementsData } = useQuery(QUERY_ANNOUNCEMENTS, {
    variables: {
      public_only: user === undefined
    }
  });

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(announcementsData?.queryAnnouncements?.announcements);
  }, [announcementsData]);

  /* Update Announcement */
  const [updatedAnnouncement, setUpdatedAnnouncement] = useState({});

  const [updateAnnouncement, { data: updateAnnouncementData, loading: updateAnnouncementLoading, error: updateAnnouncementError }] = useMutation(UPDATE_ANNOUNCEMENT, {
    skip: !user,
    variables: {
      public_only: user === undefined,
      announcement_id: updatedAnnouncement.announcement_id,
      announcement_details: updatedAnnouncement.announcement_details,
    },
    refetchQueries: [QUERY_ANNOUNCEMENTS],
    onCompleted: () => {
      setUpdatedAnnouncement({});
      toast({
        title: 'Announcement updated.',
        description: "",
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    },
    onError: () => {
      setUpdatedAnnouncement({});
      toast({
        title: 'Announcement not updated.',
        description: "",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  });

  const onChangeAnnouncement = (e, announcementId) => {
    const { name, value } = e.target;
    
    const editData = data.map((announcement) =>
      announcement.announcement_id === announcementId && name ?
        { ...announcement,
          announcement_details: {
            ...announcement.announcement_details,
            [name]: name === 'announcement_date' ? moment(value).unix() : value
          }
        } : announcement
    );

    setData(editData);
  }

  const handleUpdateAnnouncement = (announcement) => {
    const announcementData = {
      ...announcement,
      announcement_details: {
        ...announcement.announcement_details,
        announcement_published_by: user.username
      }
    }
    delete(announcementData?.announcement_details?.__typename);
    setUpdatedAnnouncement(announcementData);
  };

  useEffect(() => {
    if (updatedAnnouncement?.announcement_id) {
      updateAnnouncement();
    }
  }, [updatedAnnouncement, updateAnnouncement]);

  /* Delete Announcement */
  const [deletedAnnouncement, setDeletedAnnouncement] = useState({});

  const [deleteAnnouncement, { data: deleteAnnouncementData, loading: deleteAnnouncementLoading, error: deleteAnnouncementError }] = useMutation(DELETE_ANNOUNCEMENT, {
    skip: !user,
    variables: {
      public_only: user === undefined,
      announcement_id: deletedAnnouncement.announcement_id,
    },
    refetchQueries: [QUERY_ANNOUNCEMENTS],
    onCompleted: () => {
      setDeletedAnnouncement({});
      toast({
        title: 'Announcement deleted.',
        description: "",
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    },
    onError: () => {
      setDeletedAnnouncement({});
      toast({
        title: 'Announcement not deleted.',
        description: "",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  });

  const handleDeleteAnnouncement = (announcement) => {
    setDeletedAnnouncement(announcement);
  };

  useEffect(() => {
    if (deletedAnnouncement?.announcement_id) {
      deleteAnnouncement();
    }
  }, [deleteAnnouncement, deletedAnnouncement]);

  /* Add Announcement */
  const defaultNew = {
    announcement_details: {
      announcement_date: moment().unix(),
      announcement_text: '',
      announcement_type: 'GENERAL',
      is_public: true,
    }
  };

  const [addedAnnouncement, setAddedAnnouncement] = useState(defaultNew);
  const [newAnnouncement, setNewAnnouncement] = useState({});

  const [addAnnouncement, { data: addAnnouncementData, loading: addAnnouncementLoading, error: addAnnouncementError }] = useMutation(ADD_ANNOUNCEMENT, {
    skip: !user,
    variables: {
      public_only: user === undefined,
      announcement_details: newAnnouncement.announcement_details,
    },
    refetchQueries: [QUERY_ANNOUNCEMENTS],
    onCompleted: () => {
      setAddedAnnouncement(defaultNew);
      setNewAnnouncement({});
      toast({
        title: 'Announcement added.',
        description: "",
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    },
    onError: () => {
      setAddedAnnouncement(defaultNew);
      setNewAnnouncement({});
      toast({
        title: 'Announcement not added.',
        description: "",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  });

  const onChangeAddAnnouncement = (e) => {
    const { name, value } = e.target;
    
    const announcementData = {
      announcement_details: {
        ...addedAnnouncement.announcement_details,
        [name]: name === 'announcement_date' ? moment(value).unix() : value
      }
    }

    setAddedAnnouncement(announcementData);
  }

  const handleAddAnnouncement = () => {
    const { announcement_date, announcement_text, announcement_type, is_public } = addedAnnouncement?.announcement_details;

    const addNewAnnouncement = {
      announcement_details: {
        announcement_date,
        announcement_published_by: user.username,
        announcement_text,
        announcement_type,
        is_public,
      }
    }

    setNewAnnouncement(addNewAnnouncement);
  };

  useEffect(() => {
    if (newAnnouncement?.announcement_details?.announcement_published_by) {
      addAnnouncement();
    }
  }, [newAnnouncement, addAnnouncement]);

  return (
    announcementsLoading ||
    updateAnnouncementLoading ||
    deleteAnnouncementLoading ||
    addAnnouncementLoading ? (
      <Center>
        <Spinner />
      </Center>
    ) : (
      <TableContainer>
        <Table variant='simple'>
          <TableCaption>Announcements</TableCaption>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Type</Th>
              <Th>Text</Th>
              <Th>Is Public</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
          <Tr>
              <Td w="200px">
                <Input
                  type="date"
                  name="announcement_date"
                  value={addedAnnouncement?.announcement_details?.announcement_date ? moment.utc(addedAnnouncement?.announcement_details?.announcement_date * 1000).format('yyyy-MM-DD') : undefined}
                  onChange={(e) => onChangeAddAnnouncement({
                    target: {
                      name: 'announcement_date',
                      value: e.target.value
                    }
                  })}
                />
              </Td>
              <Td>
                <Select
                  name="announcement_type"
                  value={addedAnnouncement?.announcement_details?.announcement_type}
                  onChange={(e) => onChangeAddAnnouncement(e)}
                >
                  {announcementTypeOptions.map((type, index) => (
                    <option
                      key={index}
                      value={type.value}
                    >
                        {type.label}
                      </option>
                  ))}
                </Select>
              </Td>
              <Td>
                <Textarea
                  h="190px"
                  name="announcement_text"
                  value={addedAnnouncement?.announcement_details?.announcement_text}
                  onChange={(e) => onChangeAddAnnouncement(e)}
                />
              </Td>
              <Td>
                <Select
                  name="is_public"
                  value={addedAnnouncement?.announcement_details?.is_public}
                  onChange={(e) => onChangeAddAnnouncement(e)}
                >
                  {announcementIsPublicOptions.map((type, index) => (
                    <option
                      key={index}
                      value={type.value}
                    >
                        {type.label}
                      </option>
                  ))}
                </Select>
              </Td>
              <Td>
                <HStack>
                  <Button
                    isDisabled={
                      addAnnouncementLoading ||
                      !addedAnnouncement?.announcement_details?.announcement_date ||
                      !addedAnnouncement?.announcement_details?.announcement_type ||
                      !addedAnnouncement?.announcement_details?.announcement_text ||
                      addedAnnouncement?.announcement_details?.is_public === undefined
                    }
                    onClick={() => handleAddAnnouncement()}
                  >
                    Add
                  </Button>
                </HStack>
              </Td>
            </Tr>
            {data?.map((announcement) => (
              <Tr key={announcement.announcement_id}>
                <Td w="200px">
                  <Input
                    type="date"
                    name="announcement_date"
                    value={announcement?.announcement_details?.announcement_date ? moment.utc(announcement?.announcement_details?.announcement_date * 1000).format('yyyy-MM-DD') : undefined}
                    onChange={(e) => onChangeAnnouncement({
                      target: {
                        name: 'announcement_date',
                        value: e.target.value
                      }
                    }, announcement.announcement_id)}
                  />
                </Td>
                <Td>
                  <Select
                    name="announcement_type"
                    value={announcement?.announcement_details?.announcement_type}
                    onChange={(e) => onChangeAnnouncement(e, announcement.announcement_id)}
                  >
                    {announcementTypeOptions.map((type, index) => (
                      <option
                        key={index}
                        value={type.value}
                      >
                          {type.label}
                        </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <Textarea
                    h="190px"
                    name="announcement_text"
                    value={announcement?.announcement_details?.announcement_text}
                    onChange={(e) => onChangeAnnouncement(e, announcement.announcement_id)}
                  />
                </Td>
                <Td>
                  <Select
                    name="is_public"
                    value={announcement?.announcement_details?.is_public}
                    onChange={(e) => onChangeAnnouncement(e, announcement.announcement_id)}
                  >
                    {announcementIsPublicOptions.map((type, index) => (
                      <option
                        key={index}
                        value={type.value}
                      >
                          {type.label}
                        </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <HStack>
                    <Button
                      disabled={updateAnnouncementLoading}
                      onClick={() => handleUpdateAnnouncement(announcement)}
                    >
                      Update
                    </Button>
                    <Button
                      disabled={deleteAnnouncementLoading}
                      onClick={() => handleDeleteAnnouncement(announcement)}
                    >
                      Delete
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    )
  )
};
