import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useUser } from '../../Context';

export const LoginModal = ({
  isOpen,
  onClose,
}) => {

  const { setUser } = useUser();

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Authenticator hideSignUp={true}>
          {({ signOut, user }) => {
            setUser(user);
            return (
              <>
                <Text mb={6} >Logged in as <strong>{user.username}</strong></Text>
                <Button onClick={signOut}>Logout</Button>
              </>
            )}
            }
          </Authenticator>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}