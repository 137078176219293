import { useQuery } from '@apollo/client';

import { Box, Card, CardHeader, CardBody, CardFooter, Center, Divider, Heading, Image, Link, Spinner, Stack, StackDivider, Text, VStack } from '@chakra-ui/react';

import moment from 'moment';

import { MarkdownRenderer } from '../';

import {
  QUERY_ANNOUNCEMENTS,
} from '../../Queries';

import { useUser } from '../../Context';

export const AnnouncementsHomePanel = () => {
  const { user } = useUser();

  const { loading: generalLoading, error: generalError, data: generalData } = useQuery(QUERY_ANNOUNCEMENTS, {
    variables: {
      size: 2,
      announcement_types: ['GENERAL'],
      public_only: user === undefined
    },
  });

  const { loading: shotgunLoading, error: shotgunError, data: shotgunData } = useQuery(QUERY_ANNOUNCEMENTS, {
    variables: {
      size: 2,
      announcement_types: ['SHOTGUN'],
      public_only: user === undefined
    },
  });

  const { loading: rifleLoading, error: rifleError, data: rifleData } = useQuery(QUERY_ANNOUNCEMENTS, {
    variables: {
      size: 2,
      announcement_types: ['RIFLE_PISTOL'],
      public_only: user === undefined
    },
  });

  const { loading: matchesLoading, error: matchesError, data: matchesData } = useQuery(QUERY_ANNOUNCEMENTS, {
    variables: {
      size: 2,
      announcement_types: ['MATCHES'],
      public_only: user === undefined
    },
  });

  const { loading: membersLoading, error: membersError, data: membersData } = useQuery(QUERY_ANNOUNCEMENTS, {
    variables: {
      size: 2,
      announcement_types: ['MEMBERS'],
      public_only: user === undefined
    },
  });

  if (generalLoading || shotgunLoading || rifleLoading || matchesLoading || membersLoading) {
    return (
      <Center m="24px 0">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box>
      <Card>
        <CardHeader pb={0}>
          <Heading size='md'>Recent Announcements</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            {generalData?.queryAnnouncements?.announcements?.length > 0 && (
              <Box>
                <Heading size='sm'>
                  General
                </Heading>
                {generalData?.queryAnnouncements?.announcements?.map((announcement) => (
                  <>
                    <Heading size='xs'>
                      {moment.unix(announcement.announcement_details.announcement_date).format('MMMM Do YYYY')}
                    </Heading>
                    <MarkdownRenderer children={announcement?.announcement_details?.announcement_text} />
                  </>
                ))}
              </Box>
            )}
            {matchesData?.queryAnnouncements?.announcements?.length > 0 && (
              <Box>
                <Heading size='sm'>
                  Matches
                </Heading>
                {matchesData?.queryAnnouncements?.announcements?.map((announcement) => (
                  <>
                    <Heading size='xs'>
                      {moment.unix(announcement.announcement_details.announcement_date).format('MMMM Do YYYY')}
                    </Heading>
                    <MarkdownRenderer children={announcement?.announcement_details?.announcement_text} />
                  </>
                ))}
              </Box>
            )}
            {membersData?.queryAnnouncements?.announcements?.length > 0 && (
              <Box>
                <Heading size='sm'>
                  Members
                </Heading>
                {membersData?.queryAnnouncements?.announcements?.map((announcement) => (
                  <>
                    <Heading size='xs'>
                      {moment.unix(announcement.announcement_details.announcement_date).format('MMMM Do YYYY')}
                    </Heading>
                    <MarkdownRenderer children={announcement?.announcement_details?.announcement_text} />
                  </>
                ))}
              </Box>
            )}
            {shotgunData?.queryAnnouncements?.announcements?.length > 0 && (
              <Box>
                <Heading size='sm'>
                  Shotgun
                </Heading>
                {shotgunData?.queryAnnouncements?.announcements?.map((announcement) => (
                  <>
                    <Heading size='xs'>
                      {moment.unix(announcement.announcement_details.announcement_date).format('MMMM Do YYYY')}
                    </Heading>
                    <MarkdownRenderer children={announcement?.announcement_details?.announcement_text} />
                  </>
                ))}
              </Box>
            )}
            {rifleData?.queryAnnouncements?.announcements?.length > 0 && (
              <Box>
                <Heading size='sm'>
                  Facilities
                </Heading>
                {rifleData?.queryAnnouncements?.announcements?.map((announcement) => (
                  <>
                    <Heading size='xs'>
                      {moment.unix(announcement.announcement_details.announcement_date).format('MMMM Do YYYY')}
                    </Heading>
                    <MarkdownRenderer children={announcement?.announcement_details?.announcement_text} />
                  </>
                ))}
              </Box>
            )}
          </Stack>
        </CardBody>
      </Card>
    </Box>
  )
};
