import { useMutation, useQuery } from '@apollo/client';

import {
  Center,
  Spinner,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  VStack,
  useDisclosure,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import { Select } from "chakra-react-select";

import moment from 'moment';

import { omit } from 'underscore';

import { useCallback, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';

import {
  ADD_MEMBER,
  ADMIN_GET_MEMBER,
  DELETE_MEMBER,
  GET_MEMBERS_BY_LAST_INITIAL,
  UPDATE_MEMBER,
} from '../../../../Queries';

import { useUser } from '../../../../Context';

const memberGroupOptions = [
  {
    label: 'Admins',
    value: 'admins'
  },
  {
    label: 'Members',
    value: 'members'
  },
  {
    label: 'Match Directors',
    value: 'match_directors'
  }
];

const memberTypeOptions = [
  {
    label: 'Shotgun',
    value: 'SHOTGUN'
  },
  {
    label: 'Full',
    value: 'FULL'
  },
  {
    label: 'Spouse',
    value: 'SPOUSE'
  },
  {
    label: 'Family',
    value: 'FAMILY'
  },
  {
    label: 'Life',
    value: 'LIFE'
  },
  {
    label: 'Honorary',
    value: 'HONORARY'
  },
  {
    label: 'Inactive',
    value: 'INACTIVE'
  },
  {
    label: 'Banned',
    value: 'BANNED'
  },
  {
    label: 'Senior',
    value: 'SENIOR'
  },
  {
    label: 'ASWAT',
    value: 'ASWAT'
  },
  {
    label: 'GSWAT',
    value: 'GSWAT'
  },
  {
    label: 'DPD',
    value: 'DPD'
  }
];

export const EditMemberModal = ({ isOpen, onClose, openEditMember, setOpenEditMember }) => {
  const { user } = useUser();
  const toast = useToast();

  const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();

  const { loading, error, data } = useQuery(ADMIN_GET_MEMBER, {
    skip: !openEditMember?.member_username,
    variables: {
      public_only: user === undefined,
      member_username: openEditMember?.member_username
    }
  });

  const defaultValues = {
    member_first_name: '',
    member_middle_initial: '',
    member_last_name: '',
    member_email: '',
    member_phone_number: '',
    date_of_birth: null,
    gate_card: '',
    nra_id: '',
    last_renewal_date: null,
    paid_through: null,
    last_orientation_date: null,
    member_type: '',
    member_groups: [],
    member_since: null,
  }

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({
    defaultValues
  });

  const fillForm = useCallback(() => {
    const {
      member_first_name,
      member_middle_initial,
      member_last_name,
      member_email,
      member_phone_number,
      date_of_birth,
      gate_card,
      nra_id,
      last_renewal_date,
      paid_through,
      last_orientation_date,
      member_type,
      member_groups,
      member_since
    } = data?.adminGetMember?.member_details || {};
  
    setValue('member_username', openEditMember?.member_username || '');
    setValue('member_first_name', member_first_name || '');
    setValue('member_middle_initial', member_middle_initial || '');
    setValue('member_last_name', member_last_name || '');
    setValue('member_email', member_email || '');
    setValue('member_phone_number', member_phone_number || '');
    setValue('date_of_birth', date_of_birth || null);
    setValue('gate_card', gate_card || '');
    setValue('nra_id', nra_id || '');
    setValue('last_renewal_date', last_renewal_date || null);
    setValue('paid_through', paid_through || null);
    setValue('last_orientation_date', last_orientation_date || null);
    setValue('member_type', member_type || '');
    setValue('member_groups', member_groups || '');
    setValue('member_since', member_since || null);
  }, [data]);

  const clearForm = () => {
    setValue('member_username', defaultValues?.member_username);
    setValue('member_first_name', defaultValues?.member_first_name);
    setValue('member_middle_initial', defaultValues?.member_middle_initial);
    setValue('member_last_name', defaultValues?.member_last_name);
    setValue('member_email', defaultValues?.member_email);
    setValue('member_phone_number', defaultValues?.member_phone_number);
    setValue('date_of_birth', defaultValues?.date_of_birth);
    setValue('gate_card', defaultValues?.gate_card);
    setValue('nra_id', defaultValues?.nra_id);
    setValue('last_renewal_date', defaultValues?.last_renewal_date);
    setValue('paid_through', defaultValues?.paid_through);
    setValue('last_orientation_date', defaultValues?.last_orientation_date);
    setValue('member_type', defaultValues?.member_type);
    setValue('member_groups', defaultValues?.member_groups);
    setValue('member_since', defaultValues?.member_since);
  }

  const formData = watch();

  const createOptionString = (options) => {
    const values = options.map(({ value }) => value);
    return values.join(', ');
  }

  const getSelectedValues = (values, options) => {
    const splitString = values?.split(', ');
    const optionsArray = splitString.map((string) => {
      return options.find((option) => option.value.toString() === string);
    });
    const filteredArray = optionsArray.filter((option) => option);
    return filteredArray;
  }

  useEffect(() => {
    fillForm();
  }, [data]);

  const [addMember, { data: addMemberData, loading: addMemberLoading, error: addMemberError }] = useMutation(ADD_MEMBER, {
    skip: !user || !formData?.member_username,
    variables: {
      public_only: user === undefined,
      member_details: {
        ...omit(formData, 'member_username'),
        member_first_name: formData?.member_first_name || null,
        member_middle_initial: formData?.member_middle_initial || null,
        member_last_name: formData?.member_last_name || null,
        member_email: formData?.member_email || null,
        member_phone_number: formData?.member_phone_number || null,
        member_groups: Array.isArray(formData?.member_groups) ? formData?.member_groups : formData?.member_groups?.split(","),
        date_of_birth: formData?.date_of_birth || null,
        last_renewal_date: formData?.last_renewal_date || null,
        paid_through: formData?.paid_through || null,
        last_orientation_date: formData?.last_orientation_date || null,
        member_since: formData?.member_since || null,
      },
      member_username: formData?.member_username,
    },
    refetchQueries: [ADMIN_GET_MEMBER, GET_MEMBERS_BY_LAST_INITIAL],
    onCompleted: () => {
      toast({
        title: 'Member added.',
        description: "",
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      onClose();
      clearForm();
      setOpenEditMember({});
    },
    onError: () => {
      toast({
        title: 'Member not added.',
        description: "",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  });

  const [updateMember, { data: updateMemberData, loading: updateMemberLoading, error: updateMemberError }] = useMutation(UPDATE_MEMBER, {
    skip: !user || !openEditMember?.member_username,
    variables: {
      public_only: user === undefined,
      member_details: {
        ...omit(formData, 'member_username'),
        member_first_name: formData?.member_first_name || null,
        member_middle_initial: formData?.member_middle_initial || null,
        member_last_name: formData?.member_last_name || null,
        member_email: formData?.member_email || null,
        member_phone_number: formData?.member_phone_number || null,
        member_groups: Array.isArray(formData?.member_groups) ? formData?.member_groups : formData?.member_groups?.split(","),
        date_of_birth: formData?.date_of_birth || null,
        last_renewal_date: formData?.last_renewal_date || null,
        paid_through: formData?.paid_through || null,
        last_orientation_date: formData?.last_orientation_date || null,
        member_since: formData?.member_since || null,
      },
      member_username: openEditMember?.member_username,
    },
    refetchQueries: [ADMIN_GET_MEMBER, GET_MEMBERS_BY_LAST_INITIAL],
    onCompleted: () => {
      toast({
        title: 'Member updated.',
        description: "",
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      onClose();
      clearForm();
      setOpenEditMember({});
    },
    onError: () => {
      toast({
        title: 'Member not updated.',
        description: "",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  });

  const [deleteMember, { data: deleteMemberData, loading: deleteMemberLoading, error: deleteMemberError }] = useMutation(DELETE_MEMBER, {
    skip: !user || !openEditMember?.member_username,
    variables: {
      public_only: user === undefined,
      member_username: openEditMember?.member_username,
    },
    refetchQueries: [GET_MEMBERS_BY_LAST_INITIAL],
    onCompleted: () => {
      toast({
        title: 'Member deleted.',
        description: "",
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      onClose();
      clearForm();
      deleteOnClose();
      setOpenEditMember({});
    },
    onError: () => {
      toast({
        title: 'Member not deleted.',
        description: "",
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      deleteOnClose();
    }
  });

  const onSubmit = (values) => {
    if (openEditMember?.member_username) {
      updateMember();
    } else {
      addMember();
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Center mb={4}>
                <Spinner />
              </Center>
            ) : (
              <VStack mb={2} as="form" alignItems="start" onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={errors.member_username}>
                  <FormLabel htmlFor='member_username'>Username</FormLabel>
                  <Input
                    id='member_username'
                    placeholder='username'
                    readOnly={openEditMember?.member_username}
                    {...register('member_username', {
                      required: 'This is required',
                      minLength: { value: 2, message: 'Minimum length should be 2' },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.member_username && errors.member_username.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.member_first_name}>
                  <FormLabel htmlFor='member_first_name'>First name</FormLabel>
                  <Input
                    id='member_first_name'
                    placeholder='first name'
                    {...register('member_first_name', {
                      required: 'This is required',
                      minLength: { value: 2, message: 'Minimum length should be 2' },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.member_first_name && errors.member_first_name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='member_middle_initial'>Middle Initial</FormLabel>
                  <Input
                    id='member_middle_initial'
                    placeholder='middle initial'
                    {...register('member_middle_initial', {})}
                  />
                </FormControl>
                <FormControl isInvalid={errors.member_last_name}>
                  <FormLabel htmlFor='member_last_name'>Last name</FormLabel>
                  <Input
                    id='member_last_name'
                    placeholder='last name'
                    {...register('member_last_name', {
                      required: 'This is required',
                      minLength: { value: 2, message: 'Minimum length should be 2' },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.member_last_name && errors.member_last_name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.member_email}>
                  <FormLabel htmlFor='member_email'>Email</FormLabel>
                  <Input
                    id='member_email'
                    placeholder='email'
                    {...register('member_email', {
                      required: 'This is required',
                      minLength: { value: 4, message: 'Minimum length should be 4' },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.member_email && errors.member_email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.member_phone_number}>
                  <FormLabel htmlFor='member_phone_number'>Phone number</FormLabel>
                  <Input
                    id='member_phone_number'
                    placeholder='phone number'
                    {...register('member_phone_number', {
                      required: 'This is required',
                      minLength: { value: 4, message: 'Minimum length should be 4' },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.member_phone_number && errors.member_phone_number.message}
                  </FormErrorMessage>
                </FormControl>
                <Controller
                  control={control}
                  name="date_of_birth"
                  rules={{ required: "This is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    // fieldState: { error }
                  }) =>  (
                    <FormControl id="date_of_birth" isInvalid={errors.date_of_birth}>
                      <FormLabel>Date of birth</FormLabel>
                      <Input
                        type="date"
                        name={name}
                        value={value ? moment.utc(value * 1000).format('yyyy-MM-DD') : undefined}
                        onChange={(event) => onChange(event.target.value ? moment(event.target.value).unix(): "")}
                        id='date_of_birth'
                        placeholder='date of birth'
                      />
                      <FormErrorMessage>
                        {errors.date_of_birth && errors.date_of_birth.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="member_groups"
                  rules={{ required: "This is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <FormControl isInvalid={errors.member_groups} id="member_groups">
                      <FormLabel>Member Groups</FormLabel>
                      <Select
                        isMulti
                        name={name}
                        ref={ref}
                        onBlur={onBlur}
                        value={getSelectedValues(value?.toString(), memberGroupOptions)}
                        onChange={(values) => onChange(createOptionString(values))}
                        isOptionDisabled={() => getSelectedValues(value?.toString(), memberGroupOptions).length >= 1}
                        options={memberGroupOptions}
                        placeholder="member groups"
                        closeMenuOnSelect={false}
                      />
                      <FormErrorMessage>{errors.member_groups && errors.member_groups.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="member_type"
                  rules={{ required: "This is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    // fieldState: { error }
                  }) => (
                    <FormControl isInvalid={errors.member_type} id="member_type">
                      <FormLabel>Membership Type</FormLabel>
                      <Select
                        ref={ref}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => onChange(value.value)}
                        options={memberTypeOptions}
                        placeholder="membership type"
                        value={memberTypeOptions.find((option) => option.value === value)}
                        // closeMenuOnSelect={false}
                      />

                      <FormErrorMessage>{errors.member_type && errors.member_type.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <FormControl>
                  <FormLabel htmlFor='gate_card'>Gate Card</FormLabel>
                  <Input
                    type="number"
                    id='gate_card'
                    placeholder='gate card'
                    {...register('gate_card', {})}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='nra_id'>NRA ID</FormLabel>
                  <Input
                    id='nra_id'
                    placeholder='nra id'
                    {...register('nra_id', {})}
                  />
                </FormControl>
                <Controller
                  control={control}
                  name="member_since"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    // fieldState: { error }
                  }) =>  (
                    <FormControl id="member_since">
                      <FormLabel>Member since</FormLabel>
                      <Input
                        type="date"
                        name={name}
                        value={value ? moment.utc(value * 1000).format('yyyy-MM-DD') : undefined}
                        onChange={(event) => onChange(event.target.value ? moment(event.target.value).unix(): "")}
                        id='member_since'
                        placeholder='member since'
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="last_renewal_date"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    // fieldState: { error }
                  }) =>  (
                    <FormControl id="last_renewal_date">
                      <FormLabel>Last renewal date</FormLabel>
                      <Input
                        type="date"
                        name={name}
                        value={value ? moment.utc(value * 1000).format('yyyy-MM-DD') : undefined}
                        onChange={(event) => onChange(event.target.value ? moment(event.target.value).unix(): "")}
                        id='last_renewal_date'
                        placeholder='last renewal date'
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="paid_through"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    // fieldState: { error }
                  }) =>  (
                    <FormControl id="paid_through">
                      <FormLabel>Paid through</FormLabel>
                      <Input
                        type="date"
                        name={name}
                        value={value ? moment.utc(value * 1000).format('yyyy-MM-DD') : undefined}
                        onChange={(event) => onChange(event.target.value ? moment(event.target.value).unix(): "")}
                        id='paid_through'
                        placeholder='paid through'
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="last_orientation_date"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    // fieldState: { error }
                  }) =>  (
                    <FormControl id="last_orientation_date">
                      <FormLabel>Last orientation date</FormLabel>
                      <Input
                        type="date"
                        name={name}
                        value={value ? moment.utc(value * 1000).format('yyyy-MM-DD') : undefined}
                        onChange={(event) => onChange(event.target.value ? moment(event.target.value).unix(): "")}
                        id='last_orientation_date'
                        placeholder='last orientation date'
                        />
                    </FormControl>
                  )}
                />
                <ButtonGroup pt={2}>
                  <Button
                    type='submit'
                    colorScheme='teal' 
                    isLoading={
                      loading ||
                      isSubmitting ||
                      addMemberLoading ||
                      updateMemberLoading
                    } 
                    isDisabled={
                      loading ||
                      isSubmitting ||
                      addMemberLoading ||
                      updateMemberLoading
                    }
                  >
                    Submit
                  </Button>
                  {openEditMember?.member_username && (
                    <Button
                      onClick={deleteOnOpen}
                      colorScheme='red'
                      isLoading={
                        loading ||
                        deleteMemberLoading
                      } 
                      isDisabled={
                        loading ||
                        isSubmitting ||
                        addMemberLoading ||
                        updateMemberLoading ||
                        deleteMemberLoading
                      }
                    >
                      Delete
                    </Button>
                  )}
                  <Button onClick={onClose}>Cancel</Button>
                </ButtonGroup>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={deleteIsOpen} onClose={deleteOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are You Sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <ButtonGroup pt={2}>
                <Button
                  colorScheme='red'
                  onClick={deleteMember}
                  isLoading={deleteMemberLoading}
                  isDisabled={deleteMemberLoading}
                >
                  Delete
                </Button>
                <Button onClick={deleteOnClose}>Cancel</Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
