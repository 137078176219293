import { useMutation, useQuery } from '@apollo/client';

import {
  Box,
  Center,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Textarea,
  Button,
  Select,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import moment from 'moment';

import { useState } from 'react';

import {
  GET_MEMBERS_BY_LAST_INITIAL,
} from '../../../../Queries';

import { useUser } from '../../../../Context';

import { EditMemberModal } from './EditMemberModal';

export const Members = () => {
  const { user } = useUser();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [openEditMember, setOpenEditMember] = useState(null);

  const [initial, setInitial] = useState('A');

  /* Query Members */
  const { loading, error, data } = useQuery(GET_MEMBERS_BY_LAST_INITIAL, {
    variables: {
      public_only: user === undefined,
      initial,
    }
  });
  
  return (
    loading ? (
      <Center>
        <Spinner />
      </Center>
    ) : (
      <VStack>
        <Button
          onClick={() => {
            onOpen();
            setOpenEditMember({});
          }
        }>
          Add New
        </Button>
        <Wrap>
          {String.fromCharCode(...Array(123).keys()).slice(97).split("").map((letter) => (
            <WrapItem>
              <Button p={2} minW="auto" variant="link" textDecoration={letter?.toUpperCase() === initial ? 'underline' : null} onClick={() => setInitial(letter?.toUpperCase())}>
                {letter?.toUpperCase()}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
        <TableContainer>
          <Table variant='simple'>
            <TableCaption>Members</TableCaption>
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>First Name</Th>
                <Th>Last Name</Th>
                <Th>Member Type</Th>
                <Th>Member Since</Th>
                <Th>Paid Through</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.getMembersByLastInitial?.members?.map((member) => (
                <Tr>
                  <Td>
                    <Button
                      onClick={() => {
                        onOpen();
                        setOpenEditMember(member);
                      }
                    }>
                      {member?.member_username}
                    </Button>
                  </Td>
                  <Td>{member?.member_details?.member_first_name}</Td>
                  <Td>{member?.member_details?.member_last_name}</Td>
                  <Td>{member?.member_details?.member_type}</Td>
                  <Td>{member?.member_details?.member_since ? moment.utc(member?.member_details?.member_since * 1000).format('MM/DD/YYYY') : 'Null'}</Td>
                  <Td>{member?.member_details?.paid_through ? moment.utc(member?.member_details?.paid_through * 1000).format('MM/DD/YYYY') : 'Null'}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <EditMemberModal isOpen={isOpen} onClose={onClose} openEditMember={openEditMember} setOpenEditMember={setOpenEditMember} />
      </VStack>
    )
  )
};
