import { Box, Center, Image, Text } from '@chakra-ui/react';

export const CallToAction = ({
  main,
  image,
  title,
}) => {
  return (
    <Box width={{ base: '100%', md: '33%' }}>
      <Center mt={4} mb={8}>
        <Image src={image} borderRadius='full' boxSize='150px' />
      </Center>
      <Center>
        <Text as="h3">{title}</Text>
      </Center>
      <Center px={{ base: '0', md: 4 }}>
        <Text as="p">{main}</Text>
      </Center>
    </Box>
  )
}