import { gql } from '@apollo/client';

export const GET_USER_POOL_CLIENT_ID = gql`
  query getUserPoolClientId {
    getUserPoolClientId {
      userPoolClientId
      userPoolId
    }
  }
`;

export const GET_RANGE_CAM_TOKEN = gql`
  query getRangecamToken {
    getRangecamToken {
      header
      value
    }
  }
`;

export const QUERY_ANNOUNCEMENTS = gql`
  query queryAnnouncements (
    $announcement_types: [AnnouncementType]
    $end: Float
    $next_token: String
    $size: Int
    $start: Float
  ) {
    queryAnnouncements(
      announcement_types: $announcement_types
      end: $end
      next_token: $next_token
      size: $size
      start: $start
    ) {
      announcements {
        announcement_details {
          announcement_date
          announcement_published_by
          announcement_text
          announcement_ttl
          announcement_type
          is_public
        }
        announcement_id
      }
      nextToken
    }
  }
`;

export const ADD_ANNOUNCEMENT = gql`
  mutation addAnnouncement(
    $announcement_id: String
    $announcement_details: AnnouncementDetailsInput!
  ) {
    addAnnouncement(
      announcement_id: $announcement_id
      announcement_details: $announcement_details
    ) {
      announcement_id
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation updateAnnouncement(
    $announcement_id: String!
    $announcement_details: AnnouncementDetailsInput!
  ) {
    updateAnnouncement(
      announcement_id: $announcement_id
      announcement_details: $announcement_details
    ) {
      announcement_id
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement(
    $announcement_id: String!
  ) {
    deleteAnnouncement(
      announcement_id: $announcement_id
    ) {
      announcement_id
    }
  }
`;

export const GET_MEMBERS_BY_LAST_INITIAL = gql`
  query getMembersByLastInitial(
    $initial: String!
    $next_token: String
    $size: Int
  ) {
    getMembersByLastInitial(
      initial: $initial
      next_token: $next_token
      size: $size
    ) {
      members {
        member_username
        member_details {
          date_of_birth
          gate_card
          is_current
          last_orientation_date
          last_renewal_date
          member_app_url
          member_application_id
          member_email
          member_first_name
          member_groups
          member_last_name
          member_phone_number
          member_since
          member_type
          nra_id
          paid_through
        }
      }
    }
  }
`;

export const ADMIN_GET_MEMBER = gql`
  query adminGetMember(
    $member_username: String!
  ) {
    adminGetMember(
      member_username: $member_username
    ) {
      member_username
      member_details {
        date_of_birth
        gate_card
        is_current
        login_status
        last_renewal_date
        last_orientation_date
        member_app_url
        member_application_id
        member_email
        member_first_name
        member_groups
        member_last_name
        member_phone_number
        member_since
        member_type
        nra_id
        paid_through
      }
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation addMember(
    $member_details: MemberDetailsInput!
    $member_username: String!
  ) {
    addMember(
      member_details: $member_details
      member_username: $member_username
    ) {
      member_username
      member_details {
        date_of_birth
        gate_card
        is_current
        login_status
        last_renewal_date
        last_orientation_date
        member_app_url
        member_application_id
        member_email
        member_first_name
        member_groups
        member_last_name
        member_phone_number
        member_since
        member_type
        nra_id
        paid_through
      }
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation updateMember(
    $member_details: MemberDetailsInput!
    $member_username: String!
  ) {
    updateMember(
      member_details: $member_details
      member_username: $member_username
    ) {
      member_username
      member_details {
        date_of_birth
        gate_card
        is_current
        login_status
        last_renewal_date
        last_orientation_date
        member_app_url
        member_application_id
        member_email
        member_first_name
        member_groups
        member_last_name
        member_phone_number
        member_since
        member_type
        nra_id
        paid_through
      }
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember(
    $member_username: String!
  ) {
    deleteMember(
      member_username: $member_username
    ) {
      member_username
      member_details {
        date_of_birth
        gate_card
        is_current
        login_status
        last_renewal_date
        last_orientation_date
        member_app_url
        member_application_id
        member_email
        member_first_name
        member_groups
        member_last_name
        member_phone_number
        member_since
        member_type
        nra_id
        paid_through
      }
    }
  }
`;

export const QUERY_PUBLISHED_NEWSLETTERS = gql`
  query queryPublishedNewsletters(
    $end: Float
    $next_token: String
    $size: Int
    $start: Float
  ) {
    queryPublishedNewsletters(
      end: $end
      next_token: $next_token
      size: $size
      start: $start
    ) {
      newsletters {
        newsletter_id
        newsletter_details {
          newsletter_date
          newsletter_name
          newsletter_published_by
          newsletter_published_date
          newsletter_s3_bucket
          newsletter_s3_key
          newsletter_status
          newsletter_ttl
          newsletter_url
        }
      }
      nextToken
    }
  }
`;

export const GET_NEWSLETTER = gql`
  query getNewsletter(
    $newsletter_id: String!
  ) {
    getNewsletter(
      newsletter_id: $newsletter_id
    ) {
      newsletter_id
      newsletter_details {
        newsletter_date
        newsletter_name
        newsletter_published_by
        newsletter_published_date
        newsletter_s3_bucket
        newsletter_s3_key
        newsletter_status
        newsletter_ttl
        newsletter_url
        newsletter_upload_url {
          fields
          url
        }
      }
    }
  }
`;

export const ADD_NEWSLETTER = gql`
  mutation addNewsletter(
    $newsletter_details: NewsletterDetailsInput!
    $newsletter_id: String!
  ) {
    addNewsletter (
      newsletter_details: $newsletter_details
      newsletter_id: $newsletter_id
    ) {
      newsletter_details {
        newsletter_date
        newsletter_name
        newsletter_published_by
        newsletter_published_date
        newsletter_s3_bucket
        newsletter_s3_key
        newsletter_status
        newsletter_ttl
        newsletter_upload_url {
          fields
          url
        }
        newsletter_url
      }
      newsletter_id
    }
  }
`;

export const UPDATE_NEWSLETTER = gql`
  mutation updateNewsletter(
    $newsletter_details: NewsletterDetailsInput!
    $newsletter_id: String!
  ) {
    addNewsletter (
      newsletter_details: $newsletter_details
      newsletter_id: $newsletter_id
    ) {
      newsletter_details {
        newsletter_date
        newsletter_name
        newsletter_published_by
        newsletter_published_date
        newsletter_s3_bucket
        newsletter_s3_key
        newsletter_status
        newsletter_ttl
        newsletter_upload_url {
          fields
          url
        }
        newsletter_url
      }
      newsletter_id
    }
  }
`;

export const DELETE_NEWSLETTER = gql`
  mutation deleteNewsletter(
    $newsletter_id: String!
  ) {
    deleteNewsletter(
      newsletter_id: $newsletter_id
    ) {
      newsletter_id
      newsletter_details {
        newsletter_date
        newsletter_name
        newsletter_published_by
        newsletter_published_date
        newsletter_s3_bucket
        newsletter_s3_key
        newsletter_status
        newsletter_ttl
        newsletter_url
        newsletter_upload_url {
          fields
          url
        }
      }
    }
  }
`;
