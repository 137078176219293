import { Box, Grid, GridItem, Image, Text } from '@chakra-ui/react';

import { AnnouncementsSidebar, Footer, MarkdownRenderer, WithSubnavigation } from '../../Components';
import {React} from "react";

export const Main = ({
  children,
  hasSidebar,
  pageContent
}) => {
  const { content } = pageContent;
  const { image, main, subtitle, title } = content;

  return (
    <Box>
      {WithSubnavigation()}
      <Box>
        <Box position="relative">
          {image && (
            <Image pt={{ base: "86px", md: "0" }} src={image} width="100%" alt="lorem ipsum" />
          )}
          <Box bottom={{ md: '25%' }} pos={{ base: 'static', md: 'absolute' }} mt={4} px={{ base: '42px', md: '84px' }}>
            {subtitle && (
              <Text as="h2" color={{ base: '#000000' }} fontSize={{ base: '25px' }} fontWeight="700">{subtitle}</Text>
            )}
            {title && (
              <Text as="h1" color="#FD5521" fontSize={{ base: '32px', md: '48px' }} fontWeight="700">{title}</Text>
            )}
          </Box>
        </Box>

        <Box>
            <iframe
                title="Current Weather Conditions"
                src="https://cdnres.willyweather.com/widget/loadView.html?id=121560"
                width="650"
                height="92"
                scrolling="no"></iframe>
        </Box>

        {hasSidebar ? (
          <Grid gap={6} px={{ base: '42px', md: '84px' }} templateColumns={{ base: 'repeat(12, 1fr)' }}>
            <GridItem colSpan={{ base: 12, md: 9 }} id="main" mt={{ base: 4, md: 8 }}>
              {main && (
                <Box>
                  <MarkdownRenderer children={main} />
                </Box>
              )}
              <Box px={{ base: '0', md: '0' }}>
                {children}
              </Box>
            </GridItem>
            <AnnouncementsSidebar />
          </Grid>
        ) : (
          <Box px={{ base: '42px', md: '84px' }}>
            <Box px={{ base: '0', md: '0' }} id="main" mt={{ base: 4, md: 8 }}>
              <MarkdownRenderer children={main} />
            </Box>
            <Box px={{ base: '0', md: '0' }}>
              {children}
            </Box>
          </Box>
        )}
      </Box>
      <Footer />
    </Box>
  );
};
