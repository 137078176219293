import { Box, Flex, Image, Link, Stack, Text } from '@chakra-ui/react';

export const ButtonCallToAction = ({
  image,
  title,
  subtitle,
  buttonText,
  buttonLink
}) => {
  return (
    <Stack w="100%" spacing={0} direction={{ base: 'column', md: 'row' }} justifyContent="center">
      <Box w={{ base: '100%', md: '310px' }}>
        <Image w="100%" src={image} />
      </Box>
      <Flex w={{ base: '100%', md: '310px' }} bg="#EEE" direction="column" justifyContent="space-around" textAlign={{ base: 'center', md: 'left' }}>
        <Flex direction='column'>
          <Box mx={8} mt={4}>
            <Text style={{fontWeight: 'bold'}} as="h2">{title}</Text>
          </Box>
          <Box mx={8} mt={4}>
            <Text as="p">{subtitle}</Text>
          </Box>
        </Flex>
        <Flex mx={8}>
          <Link className="cta-button" href={buttonLink} bg="#FD5521" color="#FFF" fontWeight="600" m={{ base: "24px auto", md: "0" }} p="22px 32px" borderRadius="6px">{buttonText}</Link>
        </Flex>
      </Flex>
    </Stack>
  );
};
