import { Button, Link, HStack, VStack, Text, useDisclosure } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useUser } from '../../Context';
import { LoginModal } from '../../Components';

export const RangeCamera = () => {
  const { user } = useUser();
  const { isOpen: isLoginOpen, onClose: onLoginClose, onOpen: onLoginOpen } = useDisclosure();

  return (
    // !user ? (
    //   <>
    //     <Text>
    //       <Button
    //         as={'a'}
    //         variant={'link'}
    //         onClick={onLoginOpen}>Login</Button> to view the Range Camera.
    //     </Text>
    //     <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
    //   </>
    // ) : (
    //   <iframe src="https://feed.goldengunclub.us/rangecam" title="Range Camera" id="range-cam" style={{ aspectRatio: "16 / 9" }} width="100%" height="100%" />
    // )
    <VStack alignItems="start">
      <HStack>
        <Link isExternal href="https://feed.goldengunclub.us/rangecam">Click here to view the Range Camera feed</Link><ExternalLinkIcon mx='2px' />
      </HStack>
      <Text>* requires a goldengunclub.us member login account</Text>
    </VStack>
  )
}