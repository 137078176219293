import {
  Box,
  Code,
  Heading,
  Image,
  Link,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

export const MarkdownRenderer = ({
  children,
}) => {
  return (
    <Box>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <Heading as="h1" fontSize="h1" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <Heading as="h2" fontSize="h2" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <Heading as="h3" fontSize="h3" {...props} />
          ),
          h4: ({ node, ...props }) => (
            <Heading as="h4" fontSize="h4" {...props} />
          ),
          h5: ({ node, ...props }) => (
            <Heading as="h5" fontSize="xl" {...props} />
          ),
          h6: ({ node, ...props }) => (
            <Heading as="h6" fontSize="lg" {...props} />
          ),
          p: ({ node, ...props }) => <Text mb={2} {...props} />,
          a: ({ node, ...props }) => <Link {...props} />,
          img: ({ node, ...props }) => <Image {...props} />,
          table: ({ node, ...props }) => (
            <Table {...props} bg="gray.900" my={4} />
          ),
          thead: ({ node, ...props }) => <Thead {...props} />,
          tbody: ({ node, ...props }) => <Tbody {...props} />,
          tr: ({ node, ...props }) => <Tr {...props} />,
          th: ({ node, ...props }) => <Th {...props} />,
          td: ({ node, ...props }) => <Td {...props} />,
          ol: ({ node, ...props }) => <OrderedList {...props} start={1} />,
          ul: ({ node, ...props }) => <UnorderedList {...props} />,
          li: ({ node, ...props }) => <ListItem {...props} />,
          code: ({ node, ...props }) => (
            <Code
              {...props}
              bg="layer.0"
              color="text.primary"
              px={2}
              borderRadius="2px"
            />
          ),
        }}
      >
        {children}
      </ReactMarkdown>
    </Box>
  );
};