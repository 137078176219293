import { useMutation, useQuery } from '@apollo/client';

import {
  Center,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Textarea,
  HStack,
  Button,
  Select,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';

import moment from 'moment';

import { useUser } from '../../../../Context';

import {
  QUERY_PUBLISHED_NEWSLETTERS,
} from '../../../../Queries';
import { AddNewsletterModal, EditNewsletterModal } from '.';

export const Newsletters = () => {
  const { user } = useUser();

  const [time, setTime] = useState(0);
  const [data, setData] = useState(null);

  /* Query Newsletters */
  const { loading: newslettersLoading, error: newslettersError, data: newslettersData } = useQuery(QUERY_PUBLISHED_NEWSLETTERS, {
    skip: !user,
    // skip: !user || !time,
    variables: {
      // start: 0,
      // end: time,
      public_only: user === undefined
    }
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const [openEditNewsletter, setOpenEditNewsletter] = useState(null);

  useEffect(() => {
    setTime(moment().unix());
  }, []);

  useEffect(() => {
    setData(newslettersData?.queryPublishedNewsletters?.newsletters);
  }, [newslettersData]);

  return (
    newslettersLoading ? (
      <Center>
        <Spinner />
      </Center>
    ) : (
      <>
        <VStack>
          <Button
            onClick={() => {
              onOpen();
            }
          }>
            Add New
          </Button>
          <TableContainer>
            <Table variant='simple'>
              <TableCaption>Newsletters</TableCaption>
              <Thead>
                <Tr>
                  <Th>Newsletter Name</Th>
                  <Th>Published By</Th>
                  <Th>Published Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((newsletter) => (
                  <Tr key={newsletter?.newsletter_details?.newsletter_s3_key}>
                    <Td>
                      <Button
                        onClick={() => {
                          onEditOpen();
                          setOpenEditNewsletter(newsletter);
                        }
                      }>
                        {newsletter?.newsletter_details?.newsletter_name}
                      </Button>
                    </Td>
                    <Td>{newsletter?.newsletter_details?.newsletter_published_by}</Td>
                    <Td>{newsletter?.newsletter_details?.newsletter_published_date ? moment.utc(newsletter?.newsletter_details?.newsletter_published_date * 1000).format('MM/DD/YYYY') : 'Null'}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
        <AddNewsletterModal isOpen={isOpen} onClose={onClose} />
        <EditNewsletterModal isOpen={isEditOpen} onClose={onEditClose} openEditNewsletter={openEditNewsletter} setOpenEditNewsletter={setOpenEditNewsletter} />
      </>
    )
  )
};
