import { Box, Link, Text, VStack, Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';

import pageContent from './about-us.json';

export const AboutUs = () => {
  const { contact } = pageContent;

  return (
    <Box mt={6}>
      <Box>Committee/Match Directors:</Box>
      <TableContainer my="16px">
        <Table size='sm'>
          <Tbody>
            <Tr>
              <Td>Black Powder Cartridge</Td>
              <Td>Mike Rix</Td>
              <Td>rmrix@hotmail.com</Td>
            </Tr>
            <Tr>
              <Td>Cowboy Silhouette</Td>
              <Td>Rich DelPizzo</Td>
              <Td>303-917-2875</Td>
            </Tr>
            <Tr>
              <Td>Service Rifle</Td>
              <Td>Matt Anthony</Td>
              <Td>statmatt@gmail.com, 720-775-5243</Td>
            </Tr>
            <Tr>
              <Td>Bowling Pin Pistol</Td>
              <Td>Jared Pedri</Td>
              <Td>jared@effectiveft.com</Td>
            </Tr>
            <Tr>
              <Td>NRA Action Pistol</Td>
              <Td>Jim Flagg</Td>
              <Td>b737jim@aol.com</Td>
            </Tr>
            <Tr>
              <Td>Shotgun/Clubhouse</Td>
              <Td>Paul Pedri</Td>
              <Td>303-366-6970</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Text mb={4}><em>{contact.heading}</em></Text>
        <VStack align="flex-start">
          <Box>{contact.address}</Box>
          <Box>{contact.phoneOne}<br />{contact.phoneTwo}</Box>
          <Box><Link href={`mailto:${contact.email}`}>{contact.email}</Link></Box>
        </VStack>
      </Box>
      <Box p="24px 0">
        <iframe title="Golden Gun club Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d392972.15216102207!2d-104.88614016857603!3d39.692569299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876cf59c393028fb%3A0x44253d4150be40d2!2s1503%20S%20Watkins%20Rd%2C%20Watkins%2C%20CO%2080137!5e0!3m2!1sen!2sus!4v1667873170539!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </Box>
    </Box>
  )
}